




























import Vue, { PropType, VueConstructor } from 'vue';
import AdminVoucherService from '@/services/admin/AdminVoucherService';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ToastMessage from '@/models/ToastMessage';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import CreateCard from '@/components/forms/CreateCard.vue';
import { VoucherEditDto } from './AdminVoucherEdit.vue';

const validations = {
  voucherTransaction: {
    voucherTransactionType: { required },
    amount: { required },
    note: {},
  },
};

class VoucherTransactionDto {
  transactionAt!: string;
  note!: string;
  orderId!: string;
  amount!: string;
  voucherTransactionType = '';
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedSelect, CreateCard, ValidatedInput },
  props: {
    voucher: {
      required: true,
      type: VoucherEditDto,
    },
    availableVoucherTransactionTypes: {
      required: true,
      type: Array as PropType<Array<string>>,
    },
  },
  validations,
  mixins: [validationMixin, HelpCardMixin],
  data() {
    return {
      loading: false,
      errorMessage: '',
      message: '',
      voucherTransaction: new VoucherTransactionDto(),
    };
  },
  computed: {
    availableVoucherTransactionTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherTransactionTypes.map((type) => {
        return { value: type, label: type };
      });
      options = [{ value: '', label: '' }, ...options];
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },
  },
  methods: {
    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        return AdminVoucherService.createTransaction({ ...this.voucherTransaction, voucherId: this.voucher.id }).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
            );
            this.loading = false;
            this.$emit('createdTransaction');
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
            );
            this.loading = false;
            this.errorMessage = error;
          }
        );
      }
    },
  },
});

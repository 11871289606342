




















































































































import Vue, { VueConstructor } from 'vue';
import AdminVoucherService from '@/services/admin/AdminVoucherService';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';
import { helpers, maxValue, minLength, minValue, required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ToastMessage from '@/models/ToastMessage';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import AdminVoucherTransactionCreatePart from './AdminVoucherTransactionCreatePart.vue';
import { isAfter, isSameDay, parse } from 'date-fns';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';

class VoucherTransactionDto {
  transactionAt!: string;
  note!: string;
  orderId!: string;
  amount!: string;
  voucherTransactionType = '';
}

export class VoucherEditDto {
  id!: string;
  code!: string;
  voucherScopeType!: string;
  amount!: string;
  percentage!: string;
  companyId!: string;
  discountType!: string;
  minimalOrderAmount!: string;
  validUntil!: string | null;
  validFrom!: string;
  note!: string;
  noValidUntil!: boolean;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: {
    ValidatedSelect,
    DataTable,
    AdminVoucherTransactionCreatePart,
    ValidatedInput,
    ValidatedCheckbox,
    ValidatedTextArea,
    FormatedTimestamp,
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminVoucherIndex.meta.title').toString(),
    };
  },
  props: {
    voucherId: {
      required: true,
      type: String,
    },
  },
  mixins: [validationMixin, FormaterMixin],
  data() {
    return {
      voucher: new VoucherEditDto(),
      loading: false,
      ready: false,
      errorMessage: '',
      availableVoucherScopeTypes: [] as string[],
      availableVoucherTransactionTypes: [] as string[],
      availableVoucherDiscountTypes: [] as string[],
      companies: [] as { id: string; name: string }[],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'transactionAt', asc: false },
      currentPage: 1,
      message: '',
      voucherTransactions: {} as { items: VoucherTransactionDto[] },
      voucherTransaction: new VoucherTransactionDto(),
      noValidUntil: false,
    };
  },
  validations() {
    return {
      voucher: {
        voucherScopeType: { required },
        companyId: {
          required: requiredIf((voucher: VoucherEditDto) => {
            return voucher.voucherScopeType == 'Company';
          }),
        },
        percentage: {
          minValue: minValue(0),
          maxValue: maxValue(100),
        },
        code: { minLength: minLength(4) },
        minimalOrderAmount: { minValue: minValue(0) },
        validUntil: {
          required: requiredIf((voucher: VoucherEditDto) => {
            return !voucher.noValidUntil;
          }),
          beforeValidUntil: helpers.withParams({ type: 'minDate' }, (value: string, object: { validFrom: string }) => {
            if (!value) {
              return true;
            }
            const validUntil = parse(value, 'yyyy-MM-dd', new Date());
            const validFrom = parse(object.validFrom, 'yyyy-MM-dd', new Date());
            return isAfter(validUntil, validFrom) || isSameDay(validUntil, validFrom);
          }),
        },
        validFrom: {
          beforeValidUntil: helpers.withParams({ type: 'minDate' }, (value: string, object: { validUntil: string }) => {
            if (!object.validUntil || !value) {
              return true;
            }
            const validFrom = parse(value, 'yyyy-MM-dd', new Date());
            const validUntil = parse(object.validUntil, 'yyyy-MM-dd', new Date());
            return isAfter(validUntil, validFrom) || isSameDay(validUntil, validFrom);
          }),
        },
        note: {},
        noValidUntil: {},
      },
      noValidUntil: {},
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  watch: {
    noValidUntil: {
      handler() {
        this.voucher.noValidUntil = this.noValidUntil;
        if (this.voucher.noValidUntil) {
          this.voucher.validUntil = null;
        }
      },
    },
    sorterValue: function () {
      this.loadTransactionData();
    },
    currentPage: function () {
      this.loadTransactionData();
    },
    tableFilterValue: function () {
      this.loadTransactionData();
    },
  },
  computed: {
    showValidUntil(): boolean {
      return !this.noValidUntil;
    },

    colCount(): number {
      return this.voucher.discountType != 'Percentage' ? 9 : 12;
    },

    availableVoucherDiscountTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherDiscountTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },

    availableVoucherScopeTypeOptions(): { value: string; label: string }[] {
      let options = this.availableVoucherScopeTypes.map((type) => {
        return { value: type, label: type };
      });
      options.sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },

    availableCompanyOptions(): { value: string; label: string }[] {
      let options = this.companies.map((company) => {
        return { value: company.id, label: company.name };
      });
      options = [{ value: '', label: '' }, ...options];
      options.filter((c) => !!c.label).sort((a, b) => a.label.localeCompare(b.label));
      return options;
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminVoucherIndex.breadcrumb.last' },
      ]);
    },

    handleCancel() {
      this.$router.push({ name: 'AdminVoucherIndex' });
    },

    loadTransactionData() {
      AdminVoucherService.getVoucherTransactions(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue,
        this.voucherId
      ).then((res) => {
        this.voucherTransactions = res.value.voucherTransactions;
        this.loading = false;
        this.ready = true;
      });
    },

    loadData() {
      this.loading = true;
      AdminVoucherService.getEdit(this.voucherId).then((res) => {
        this.voucher = res.value.voucher;
        this.voucher.noValidUntil = this.voucher.validUntil == null;
        this.noValidUntil = this.voucher.validUntil == null;
        this.availableVoucherScopeTypes = res.value.availableVoucherScopeTypes;
        this.availableVoucherTransactionTypes = res.value.availableVoucherTransactionTypes;
        this.availableVoucherDiscountTypes = res.value.availableVoucherDiscountTypes;
        this.companies = res.value.companies;
        this.setBreadCrumb();
        this.loadTransactionData();
      });
    },
    handleSubmit() {
      this.$v.$reset();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        return AdminVoucherService.update({ ...this.voucher }).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
            );
            this.$router.push({
              name: 'AdminVoucherIndex',
            });
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
            );
            this.loading = false;
            this.errorMessage = error;
          }
        );
      }
    },
  },
});
